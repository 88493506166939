import React, { useState } from 'react';
import Select from 'react-select';
import './Form.css'

const Question = ({ label, description, name, codigo, value, onChange, isFieldEmpty, isValid = true, invalidMessage = '', type = 'text', options = [], isVisible = true, placeholder, handleFile, className, containerClassName, negritaClassName, grisClassName  }) => {
    const [fileName, setFileName] = useState('');

    if (!isVisible) return null;  // Si no es visible, no renderiza nada
    
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFileName(file.name);  // Establece el nombre del archivo seleccionado
            onChange(e);  // Ejecuta el evento onChange que ya tienes definido
        }
    };

    const renderInput = () => {
        switch (type) {
            case 'select':
                return (
                    <select
                        id={name}
                        name={name}
                        value={value}
                        onChange={onChange}
                        className={className ? className : `form-control ${isFieldEmpty ? 'form-control-no-respondido' : 'form-control'}`}
                    >
                        <option value="" disabled>Seleccione una opción</option>
                        {options.map(option => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                );
            case 'tel':
                return (
                    <div className='telephony-fields' style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            type="text"
                            id="codigo-telefonico"
                            name="codigo-telefonico"
                            value={codigo}
                            readOnly
                            className="form-control codigo-input"
                            style={{ width: '50px', marginRight: '10px' }} // Tamaño del campo de código
                        />
                        <input
                            type="tel"
                            id={name}
                            name={name}
                            value={value || ''}
                            onChange={onChange}
                            className={className ? className : `form-control ${isFieldEmpty ? 'form-control-no-respondido' : 'form-control'}`}
                        />
                    </div>
                );
            case 'multi-select':
                return (
                    <Select
                        className={className ? className : 'input-multi-select'}
                        value={value}
                        onChange={(selectedOptions) => onChange(selectedOptions, name)}
                        isMulti
                        options={options}
                    />
                );
            case 'textarea':
                return (
                    <textarea
                        id={name}
                        name={name}
                        value={value}
                        onChange={onChange}
                        placeholder={placeholder}
                        className={className ? className : `form-control-brief ${isFieldEmpty ? 'form-control-brief-no-respondido' : 'form-control-brief'}`} 
                        maxLength="350"
                    />
                );
            case 'file':
                return (
                    <div className='cv-container'>
                        <label className="label-cv">Adjuntar CV:</label>
                        <label className="custom-file-label" htmlFor={name}>Seleccionar archivo</label>
                        <input 
                            type='file' 
                            id={name}
                            name={name} 
                            accept='.pdf' 
                            onChange={handleFileChange} 
                            className="cv" 
                            style={{ display: 'none' }} // Ocultamos el input original
                        />
                        <span className="file-chosen">{fileName ? fileName : 'Sin archivos seleccionados'}</span>
                        <button className='button-cv' onClick={handleFile}>Enviar</button>
                    </div>
                );
            case 'checkbox':
                return (
                    <label htmlFor="brief" className="label-negrita">
                        <input
                            type="checkbox"
                            checked={value}
                            name={name}
                            onChange={onChange}
                            className={className ? className : 'checkbox-default'}
                        />
                        {placeholder}
                    </label>
                );
            default:
                return (
                    <input
                        type={type}
                        id={name}
                        name={name}
                        value={value}
                        onChange={onChange}
                        className={className ? className : `form-control ${isFieldEmpty ? 'form-control-no-respondido' : 'form-control'}`}
                    />
                );
        }
    };

    return (
        <div className={containerClassName ? containerClassName : 'form-group'} >
            <label className={negritaClassName ? negritaClassName : 'label-negrita'} >{label}</label>
            {description && name === 'id_personalidad' ? (
                <a href={description} target="_blank" className={grisClassName ? grisClassName : 'label-gris'}>{description}</a>
            ) : (
                <label className={grisClassName ? grisClassName : 'label-gris'}>{description}</label>
            )}
            {renderInput()}
            {!isValid && <label className="label-roja">{invalidMessage}</label>}
        </div>
    );
};

export default Question;
