import Header from '../Header/Header';
import Carousel from './Carousel';  // Importa el carrusel
import Statistics from './Statistics';
import Logos from './Logos';
import './Home.css';
import imagen_wot from '../../img/WoT.png';

export default function Home() {

  return (
    <div>
      <Header />
      <div>
      <div className='bienvenida'>
          BIENVENIDA A WOMAN TALENT
        </div>
      </div>
      <hr className="divider" />

      <div className="description-home">
        <h2>TRES COSAS QUE DEBES SABER ANTES DE COMENZAR USANDO NUESTRA PLATAFORMA:</h2>
      </div>
      <Statistics />
      <Carousel />
      
      <hr className="divider" />
    </div>
  );
}
