import React, { useState } from 'react';
import './Auth.css';
import { ThemeProvider } from "@mui/material";
import { Images } from './Images';
import { useNavigate } from 'react-router-dom';
import { MUItheme } from './MUItheme';
import { NormalInput } from './NormalInput';
import { SubmitButton } from './SubmitButton';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function ForgotPassword() {

  const [formData, setFormData] = useState({
    mail: '',
  });

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const navigate = useNavigate();


  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/forgot_password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ mail: formData.mail }),
      });
      if (response.status === 201) {
        navigate('/enterverificationcode', { state: { mail: formData.mail } });
      }
    } catch (error) {
      alert('Mail no existe')
      console.error(error);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className='main-container'>
          <Images/>
          <div className='olvidar-container'>
          <ThemeProvider theme={MUItheme}>
            <h3 className='message-forgot-password'>¿Olvidaste tu contraseña?</h3>
            <h3 className='instructions-forgot-password'>{'Ingresa tu correo para recuperar tu contraseña'}</h3>
            <link href='https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap' rel='stylesheet'></link>
            <NormalInput text={'Correo electrónico'} onInputChange={handleInputChange} type={'mail'} value={'mail'}/>
            <SubmitButton handleLogin={handleLogin} buttonText={'Enviar código'} buttonColor= "#53565A"/>
          </ThemeProvider>
    </div>
      </div>
    </>
  )
  
}

export default ForgotPassword;