import React from 'react';
import Modal from 'react-modal';
import '../Header/Header.css';

function LogoutModal({ isOpen, onClose, onLogout }) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="logout-modal-content"
      overlayClassName="logout-modal-overlay"
    >
      <link href='https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap' rel='stylesheet'></link>
      <p className="logout-message">¿Estás seguro que deseas cerrar la sesión?</p>
      <div className="logout-modal-buttons">
        <button className="cancel-button" onClick={onClose}>Cancelar</button>
        <button className="logout-button" onClick={onLogout}>Cerrar Sesión</button>
      </div>
    </Modal>
  );
}
export default LogoutModal;