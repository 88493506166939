import React, { useState } from 'react';
import './Resume.css';
import Edit from './Editar';

const Resume = ({ title, description, step, onEdit, onDelete }) => {

  const handleEditClick = () => {
    onEdit(step); // Llamamos al padre cuando se clickea Editar
  };

  const handleDeleteClick = () => {
    onDelete(step)
  }


  return (
    <div className="section-container">
      <div className="section-header">
        <h2>{title}</h2>
        <div className="section-actions">
          <button className="btn-edit" onClick={handleEditClick}>
            Editar
          </button>
          <button className="btn-delete" onClick={handleDeleteClick}>Eliminar</button>
        </div>
      </div>
      <p className="section-description">{description}</p>
    </div>
  );
};

export default Resume;
