import React from 'react';
import logo from '../../img/WoT.png';
import people from '../../img/People.png';

export function Images() {
  return (
    <div className='left-container'>
      <img src={logo} alt='WoT' className="logo-login" />
      <img src={people} alt='People' className='image-login' />
      <link href='https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap' rel='stylesheet'></link>
      <h3 className='message-login'>Conectando talento</h3>
    </div>
  );
}