const phoneCode = [
    {"nombre": "Afganistán", "codigo": "+93"},
    {"nombre": "Sudáfrica", "codigo": "+27"},
    {"nombre": "Albania", "codigo": "+355"},
    {"nombre": "Alemania", "codigo": "+49"},
    {"nombre": "Andorra", "codigo": "+376"},
    {"nombre": "Angola", "codigo": "+244"},
    {"nombre": "Anguila", "codigo": "+1-264"},
    {"nombre": "Antigua y Barbuda", "codigo": "+1-268"},
    {"nombre": "Antillas Holandesas", "codigo": "+599"},
    {"nombre": "Arabia Saudita", "codigo": "+966"},
    {"nombre": "Argelia", "codigo": "+213"},
    {"nombre": "Argentina", "codigo": "+54"},
    {"nombre": "Armenia", "codigo": "+374"},
    {"nombre": "Aruba", "codigo": "+297"},
    {"nombre": "Australia", "codigo": "+61"},
    {"nombre": "Austria", "codigo": "+43"},
    {"nombre": "Azerbaiyán", "codigo": "+994"},
    {"nombre": "Bahamas", "codigo": "+1-242"},
    {"nombre": "Bangladesh", "codigo": "+880"},
    {"nombre": "Barbados", "codigo": "+1-246"},
    {"nombre": "Baréin", "codigo": "+973"},
    {"nombre": "Bélgica", "codigo": "+32"},
    {"nombre": "Belice", "codigo": "+501"},
    {"nombre": "Benín", "codigo": "+229"},
    {"nombre": "Islas Bermudas", "codigo": "+1-441"},
    {"nombre": "Bielorrusia", "codigo": "+375"},
    {"nombre": "Bolivia", "codigo": "+591"},
    {"nombre": "Bosnia Herzegovina", "codigo": "+387"},
    {"nombre": "Botsuana", "codigo": "+267"},
    {"nombre": "Brasil", "codigo": "+55"},
    {"nombre": "Brunéi", "codigo": "+673"},
    {"nombre": "Bulgaria", "codigo": "+359"},
    {"nombre": "Burkina Faso", "codigo": "+226"},
    {"nombre": "Burundi", "codigo": "+257"},
    {"nombre": "Bután", "codigo": "+975"},
    {"nombre": "Cabo Verde", "codigo": "+238"},
    {"nombre": "Camerún", "codigo": "+237"},
    {"nombre": "Camboya", "codigo": "+855"},
    {"nombre": "Canadá", "codigo": "+1"},
    {"nombre": "Chile", "codigo": "+56"},
    {"nombre": "Qatar", "codigo": "+974"},
    {"nombre": "Kazajstán", "codigo": "+7"},
    {"nombre": "Chad", "codigo": "+235"},
    {"nombre": "China", "codigo": "+86"},
    {"nombre": "Chipre", "codigo": "+357"},
    {"nombre": "Colombia", "codigo": "+57"},
    {"nombre": "Congo", "codigo": "+242"},
    {"nombre": "Corea del Norte", "codigo": "+850"},
    {"nombre": "Corea del Sur", "codigo": "+82"},
    {"nombre": "Costa de Marfil", "codigo": "+225"},
    {"nombre": "Costa Rica", "codigo": "+506"},
    {"nombre": "Croacia", "codigo": "+385"},
    {"nombre": "Cuba", "codigo": "+53"},
    {"nombre": "Curazao", "codigo": "+599"},
    {"nombre": "Dinamarca", "codigo": "+45"},
    {"nombre": "Yibuti", "codigo": "+253"},
    {"nombre": "Dominica", "codigo": "+1-767"},
    {"nombre": "Egipto", "codigo": "+20"},
    {"nombre": "El Salvador", "codigo": "+503"},
    {"nombre": "Emiratos Árabes Unidos", "codigo": "+971"},
    {"nombre": "Ecuador", "codigo": "+593"},
    {"nombre": "Eritrea", "codigo": "+291"},
    {"nombre": "Eslovaquia", "codigo": "+421"},
    {"nombre": "Eslovenia", "codigo": "+386"},
    {"nombre": "España", "codigo": "+34"},
    {"nombre": "Estados Unidos", "codigo": "+1"},
    {"nombre": "Estonia", "codigo": "+372"},
    {"nombre": "Etiopía", "codigo": "+251"},
    {"nombre": "Fiyi", "codigo": "+679"},
    {"nombre": "Filipinas", "codigo": "+63"},
    {"nombre": "Finlandia", "codigo": "+358"},
    {"nombre": "Francia", "codigo": "+33"},
    {"nombre": "Gabón", "codigo": "+241"},
    {"nombre": "Gambia", "codigo": "+220"},
    {"nombre": "Ghana", "codigo": "+233"},
    {"nombre": "Gibraltar", "codigo": "+350"},
    {"nombre": "Granada", "codigo": "+1-473"},
    {"nombre": "Grecia", "codigo": "+30"},
    {"nombre": "Groenlandia", "codigo": "+299"},
    {"nombre": "Guadalupe", "codigo": "+590"},
    {"nombre": "Guam", "codigo": "+1-671"},
    {"nombre": "Guatemala", "codigo": "+502"},
    {"nombre": "Guayana", "codigo": "+592"},
    {"nombre": "Guayana Francesa", "codigo": "+594"},
    {"nombre": "Guinea", "codigo": "+224"},
    {"nombre": "Guinea Ecuatorial", "codigo": "+240"},
    {"nombre": "Guinea-Bisáu", "codigo": "+245"},
    {"nombre": "Haití", "codigo": "+509"},
    {"nombre": "Países Bajos", "codigo": "+31"},
    {"nombre": "Honduras", "codigo": "+504"},
    {"nombre": "Hong Kong", "codigo": "+852"},
    {"nombre": "Hungría", "codigo": "+36"},
    {"nombre": "Yemen", "codigo": "+967"},
    {"nombre": "Isla Bouvet", "codigo": "+47"},
    {"nombre": "Isla Periférica Menor", "codigo": "+1"},
    {"nombre": "Isla Norfolk", "codigo": "+672"},
    {"nombre": "Isla Reunión", "codigo": "+262"},
    {"nombre": "Isla Santo Tomé y Príncipe", "codigo": "+239"},
    {"nombre": "Islas Caimán", "codigo": "+1-345"},
    {"nombre": "Islas de Navidad", "codigo": "+61"},
    {"nombre": "Islas de Coco", "codigo": "+61"},
    {"nombre": "Islas Comoras", "codigo": "+269"},
    {"nombre": "Islas Cook", "codigo": "+682"},
    {"nombre": "Islas Malvinas", "codigo": "+500"},
    {"nombre": "Islas Faroe", "codigo": "+298"},
    {"nombre": "Islas Heard & McDonald", "codigo": "+672"},
    {"nombre": "Islas Marianas del Norte", "codigo": "+1-670"},
    {"nombre": "Islas Marshall", "codigo": "+692"},
    {"nombre": "Islas Mauricio", "codigo": "+230"},
    {"nombre": "Islas Salomón", "codigo": "+677"},
    {"nombre": "Islas Svalbard y Jan Mayan", "codigo": "+47"},
    {"nombre": "Islas Turcas y Caicos", "codigo": "+1-649"},
    {"nombre": "Islas Vírgenes de los Estados Unidos", "codigo": "+1-340"},
    {"nombre": "Islas Vírgenes Británicas", "codigo": "+1-284"},
    {"nombre": "Islas Wallis y Futuna", "codigo": "+681"},
    {"nombre": "India", "codigo": "+91"},
    {"nombre": "Indonesia", "codigo": "+62"},
    {"nombre": "Inglaterra", "codigo": "+44"},
    {"nombre": "Irán", "codigo": "+98"},
    {"nombre": "Irak", "codigo": "+964"},
    {"nombre": "Irlanda", "codigo": "+353"},
    {"nombre": "Islandia", "codigo": "+354"},
    {"nombre": "Israel", "codigo": "+972"},
    {"nombre": "Italia", "codigo": "+39"},
    {"nombre": "Yugoslavia", "codigo": "+38"},
    {"nombre": "Jamaica", "codigo": "+1-876"},
    {"nombre": "Japón", "codigo": "+81"},
    {"nombre": "Jordán", "codigo": "+962"},
    {"nombre": "Kiribati", "codigo": "+686"},
    {"nombre": "Kuwait", "codigo": "+965"},
    {"nombre": "Laos", "codigo": "+856"},
    {"nombre": "Lesoto", "codigo": "+266"},
    {"nombre": "Letonia", "codigo": "+371"},
    {"nombre": "Líbano", "codigo": "+961"},
    {"nombre": "Liberia", "codigo": "+231"},
    {"nombre": "Libia", "codigo": "+218"},
    {"nombre": "Liechtenstein", "codigo": "+423"},
    {"nombre": "Lituania", "codigo": "+370"},
    {"nombre": "Luxemburgo", "codigo": "+352"},
    {"nombre": "Macedonia", "codigo": "+389"},
    {"nombre": "Madagascar", "codigo": "+261"},
    {"nombre": "Malasia", "codigo": "+60"},
    {"nombre": "Malauí", "codigo": "+265"},
    {"nombre": "Maldivas", "codigo": "+960"},
    {"nombre": "Malí", "codigo": "+223"},
    {"nombre": "Malta", "codigo": "+356"},
    {"nombre": "Marruecos", "codigo": "+212"},
    {"nombre": "Martinica", "codigo": "+596"},
    {"nombre": "Mauricio", "codigo": "+230"},
    {"nombre": "Mauritania", "codigo": "+222"},
    {"nombre": "Mayotte", "codigo": "+262"},
    {"nombre": "México", "codigo": "+52"},
    {"nombre": "Birmania", "codigo": "+95"},
    {"nombre": "Micronesia", "codigo": "+691"},
    {"nombre": "Mozambique", "codigo": "+258"},
    {"nombre": "Moldavia", "codigo": "+373"},
    {"nombre": "Mónaco", "codigo": "+377"},
    {"nombre": "Mongolia", "codigo": "+976"},
    {"nombre": "Montenegro", "codigo": "+382"},
    {"nombre": "Montserrat", "codigo": "+1-664"},
    {"nombre": "Namibia", "codigo": "+264"},
    {"nombre": "Nauru", "codigo": "+674"},
    {"nombre": "Nepal", "codigo": "+977"},
    {"nombre": "Nicaragua", "codigo": "+505"},
    {"nombre": "Níger", "codigo": "+227"},
    {"nombre": "Nigeria", "codigo": "+234"},
    {"nombre": "Niue", "codigo": "+683"},
    {"nombre": "Noruega", "codigo": "+47"},
    {"nombre": "Nueva Caledonia", "codigo": "+687"},
    {"nombre": "Nueva Zelanda", "codigo": "+64"},
    {"nombre": "Omán", "codigo": "+968"},
    {"nombre": "Palaos", "codigo": "+680"},
    {"nombre": "Palestina", "codigo": "+970"},
    {"nombre": "Panamá", "codigo": "+507"},
    {"nombre": "Papúa Nueva Guinea", "codigo": "+675"},
    {"nombre": "Pakistán", "codigo": "+92"},
    {"nombre": "Paraguay", "codigo": "+595"},
    {"nombre": "Perú", "codigo": "+51"},
    {"nombre": "Pitcairn", "codigo": "+64"},
    {"nombre": "Polinesia Francesa", "codigo": "+689"},
    {"nombre": "Polonia", "codigo": "+48"},
    {"nombre": "Puerto Rico", "codigo": "+1-787, +1-939"},
    {"nombre": "Portugal", "codigo": "+351"},
    {"nombre": "Kenia", "codigo": "+254"},
    {"nombre": "Kirguistán", "codigo": "+996"},
    {"nombre": "Reino Unido", "codigo": "+44"},
    {"nombre": "República Centroafricana", "codigo": "+236"},
    {"nombre": "Republica de Georgia", "codigo": "+995"},
    {"nombre": "República Democrática del Congo", "codigo": "+243"},
    {"nombre": "República Dominicana", "codigo": "+1-809, +1-829, +1-849"},
    {"nombre": "República Checa", "codigo": "+420"},
    {"nombre": "Rumania", "codigo": "+40"},
    {"nombre": "Ruanda", "codigo": "+250"},
    {"nombre": "Rusia", "codigo": "+7"},
    {"nombre": "Sahara Occidental", "codigo": "+212"},
    {"nombre": "San Bartolomé", "codigo": "+590"},
    {"nombre": "San Pedro y Miquelón", "codigo": "+508"},
    {"nombre": "Samoa Americana", "codigo": "+1-684"},
    {"nombre": "Samoa Occidental", "codigo": "+685"},
    {"nombre": "San Marino", "codigo": "+378"},
    {"nombre": "Santa Helena", "codigo": "+290"},
    {"nombre": "Santa Lucía", "codigo": "+1-758"},
    {"nombre": "San Cristóbal y Nieves", "codigo": "+1-869"},
    {"nombre": "San Martín", "codigo": "+590"},
    {"nombre": "San Vicente y las Granadinas", "codigo": "+1-784"},
    {"nombre": "Seychelles", "codigo": "+248"},
    {"nombre": "Senegal", "codigo": "+221"},
    {"nombre": "Sierra Leona", "codigo": "+232"},
    {"nombre": "Serbia", "codigo": "+381"},
    {"nombre": "Singapur", "codigo": "+65"},
    {"nombre": "Siria", "codigo": "+963"},
    {"nombre": "Somalia", "codigo": "+252"},
    {"nombre": "Sri Lanka", "codigo": "+94"},
    {"nombre": "Suazilandia", "codigo": "+268"},
    {"nombre": "Sudán", "codigo": "+249"},
    {"nombre": "Sudán del Sur", "codigo": "+211"},
    {"nombre": "Suecia", "codigo": "+46"},
    {"nombre": "Suiza", "codigo": "+41"},
    {"nombre": "Surinam", "codigo": "+597"},
    {"nombre": "Tayikistán", "codigo": "+992"},
    {"nombre": "Tailandia", "codigo": "+66"},
    {"nombre": "Taiwán", "codigo": "+886"},
    {"nombre": "Tanzania", "codigo": "+255"},
    {"nombre": "Timor Oriental", "codigo": "+670"},
    {"nombre": "Togo", "codigo": "+228"},
    {"nombre": "Tokelau", "codigo": "+690"},
    {"nombre": "Tonga", "codigo": "+676"},
    {"nombre": "Trinidad y Tobago", "codigo": "+1-868"},
    {"nombre": "Túnez", "codigo": "+216"},
    {"nombre": "Turkmenistán", "codigo": "+993"},
    {"nombre": "Turcas y Caicos", "codigo": "+1-649"},
    {"nombre": "Turquía", "codigo": "+90"},
    {"nombre": "Tuvalu", "codigo": "+688"},
    {"nombre": "Ucrania", "codigo": "+380"},
    {"nombre": "Uganda", "codigo": "+256"},
    {"nombre": "Uruguay", "codigo": "+598"},
    {"nombre": "Uzbekistán", "codigo": "+998"},
    {"nombre": "Vanuatu", "codigo": "+678"},
    {"nombre": "Venezuela", "codigo": "+58"},
    {"nombre": "Vietnam", "codigo": "+84"},
    {"nombre": "Zambia", "codigo": "+260"},
    {"nombre": "Zimbabue", "codigo": "+263"}
];

export default phoneCode;