import React, { useState } from 'react';
import "./Editar.css";
import cityImage from '../../img/Santiago.png';
import Header from '../Header/Header';
import { NextButton } from '../Form/NextButton';
import { BackButton } from '../Form/BackButton';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NormalInput } from './NormalInput';
import { HiddenInput } from './HiddenInput';
import { PasswordCriteria } from './PasswordCriteria';
import Box from '@mui/material/Box';

// Función para cambiar la contraseña
const change_password = async (email, currentPassword, newPassword) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/change-password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email,
                currentPassword,
                newPassword,
            }),
        });
        return response;
    } catch (error) {
        console.error(error);
    }
}

// Componente Formulario
const EditPassword = () => {
    const [formData, setFormData] = useState({
        email: '',
        currentPassword: '',
        newPassword: '',
    });

    const [passwordCriteria, setPasswordCriteria] = useState({
        minLength: false,
        hasUpperCase: false,
        hasNumberOrSymbol: false,
    });

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFormData({
          ...formData,
          [id]: value,
        });
    
        if (id === 'newPassword') {
          validatePassword(value);
        }
    };
    
    const validatePassword = (password) => {
        setPasswordCriteria({
          minLength: password.length >= 8,
          hasUpperCase: /[A-Z]/.test(password),
          hasNumber: /[0-9]/.test(password),
          hasSymbol: /[!@#$%^&*(),.?":{}|<>]/.test(password)
        });
    };

    const navigate = useNavigate();

    const handleCancel = () => {
        navigate('/profile');
    }

    const handleChange = async (event) => {
        event.preventDefault();
        try {
            const response = await change_password(formData.email, formData.currentPassword, formData.newPassword);
            if (response.ok) {
                toast.success('Password changed successfully!', { position: "top-center" });
                navigate('/profile');
            } else {
                throw new Error('Failed to change password');
            }
        } catch (error) {
            toast.error(`Error: ${error.message}`, { position: "top-center" });
        }
    };
    
    return (
        <div>
        <Header />
        <div className='editar-container'>
            <img src={cityImage} alt="City Background" className="image-city-editar" />
            
            {/* Fondo rosado y fondo blanco (diseño) */}
            <div className='fondo_rosado_editarContraseña'></div>
            <div className='fondo_blanco_editarContraseña'></div>
            
            <div className='container-editarContraseña'>
                <h3 className='message-forgot-password'>Cambiar Contraseña</h3>
                <h3 className='instructions-forgot-password'>Ingresa tu correo para cambiar tu contraseña</h3>
                
                <NormalInput text={'Correo electrónico'} onInputChange={handleInputChange} value={formData.email} />
                <HiddenInput text={'Contraseña Actual'} onInputChange={handleInputChange} value={formData.currentPassword} type={'currentPassword'} />
                <HiddenInput text={'Contraseña Nueva'} onInputChange={handleInputChange} value={formData.newPassword} type={'newPassword'} />

                <Box>
                    <PasswordCriteria isValid={passwordCriteria.minLength} text="8 caracteres" />
                    <PasswordCriteria isValid={passwordCriteria.hasUpperCase} text="1 mayúscula" />
                    <PasswordCriteria isValid={passwordCriteria.hasNumber && passwordCriteria.hasSymbol} text="1 número y 1 símbolo" />
                </Box>

                <div className='button-container-editarcontrasena'>
                    <BackButton buttonText="Cancelar" buttonColor="#5A5A5A" handleBack={handleCancel} />
                    <NextButton buttonText="Enviar" buttonColor="#e6007e" handleNext={handleChange} /> 
                </div>
            </div>
        </div>
        </div>
    );
};

export default EditPassword;
