import React, { useState } from 'react';
import { ThemeProvider } from "@mui/material";
import { NormalInput } from './NormalInput';
import { SubmitButton } from './SubmitButton';
import { MUItheme } from './MUItheme';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Images } from './Images';
import './Auth.css';

export function Register() {
  const [isDataVerified, setIsDataVerified] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [localError, setLocalError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [formData, setFormData] = useState({
    nombre: '',
    apellido: '',
    mail: '',
  });

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const navigate = useNavigate();

  const handleLogin = async (event, isDataVerified, acceptTerms) => {
    event.preventDefault();
    setError(false);
    setLoading(true);

    if (
      formData.nombre === '' || 
      formData.apellido === '' || 
      formData.mail === '' || 
      !formData.mail.includes('@') || 
      !isDataVerified || 
      !acceptTerms
    ) {
      setError(true);
      setLoading(false);
      toast.error('Ingresa tus datos correctamente', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
      return;
    }

    navigate('/createpassword', { 
      state: { 
        nombre: formData.nombre, 
        apellido: formData.apellido, 
        mail: formData.mail } });
  };


  const handleDataVerificationChange = () => {
    setIsDataVerified(!isDataVerified);
    setLocalError(false);
  };

  const handleAcceptTermsChange = () => {
    setAcceptTerms(!acceptTerms);
    setLocalError(false);
  };

  const handleLocalError = () => {
    setLocalError(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!isDataVerified || !acceptTerms) {
      handleLocalError();
      return;
    }

    handleLogin(event, isDataVerified, acceptTerms);
  };

  return (
    <div className='main-container'>
        <Images />
        <div className='right-container-login'>
        <ThemeProvider theme={MUItheme}>
            <h3 className='message-register'>Regístrate en WOT</h3>
            <link href='https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap' rel='stylesheet'></link>
            <NormalInput text='Nombre' value={'nombre'} onInputChange={handleInputChange}/>
            <NormalInput text='Apellido' value={'apellido'} onInputChange={handleInputChange}/>
            <NormalInput text='Email' value={'mail'} onInputChange={handleInputChange}/>

            <div className="checkbox-container">
            <label className="checkbox-label">
                <input
                type="checkbox"
                checked={isDataVerified}
                onChange={handleDataVerificationChange}
                />
                Declaro que los datos ingresados son verídicos
            </label>

            <label className="checkbox-label">
                <input
                type="checkbox"
                checked={acceptTerms}
                onChange={handleAcceptTermsChange}
                />
                Acepto los términos y condiciones
            </label>
            </div>

            <div className="terms-links">
            <a href="https://drive.google.com/file/d/1xgzD0NniJmMeO9NEfDdQAwfYpWTzbTQU/view" target="_blank" rel="noopener noreferrer">
                Términos y Condiciones de WOT
            </a>
            <span className="links-separator"></span>
            <a href="https://drive.google.com/file/d/1pa3_57nTHVXqlpa1ozRJRqgiimIRsOwt/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                Política de privacidad de WOT
            </a>
            </div>

            <SubmitButton
            handleLogin={handleSubmit}
            buttonText="Entrar" buttonColor= "#E6007E"
            isDataVerified={isDataVerified}
            acceptTerms={acceptTerms}
            />

            {localError && (
            <div className="error-message">
                Debes marcar ambas checkboxes antes de continuar.
            </div>
            )}

        </ThemeProvider>
        </div>
    </div>
  );
}

export default Register;