import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Login from './components/Auth/Login';
import ForgotPassword from './components/Auth/ForgotPassword';
import EnterVerificationCode from './components/Auth/EnterVerificationCode';
import Register from './components/Auth/Register';
// import Header from './components/Header/Header';
import Form from './components/Form/Form';
import Profile from './components/Profile/Profile';
import Header from './components/Header/Header';
import Editar from './components/Profile/EditarContrasena';
import Home from './components/Home/Home';
import CreatePassword from './components/Auth/CreatePassword';

function Log() {
  return( <div className='App'>
            <Login />
          </div>
  );
}

function Forms() {
  return( <div className='App'>
            <Form />
          </div>
  );
}

function Prof() {
  return( <div className='App'>
            <Header />
            <Profile />
          </div>
  );
}

function Edit(){
  return( 
    <div className='App'>
            <Editar />
          </div>
  )
}

// function Home(){
//   return( 
//     <div className='App'>
//             <Home />
//           </div>
//   )
// }


function App() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderContent = () => {

    return (
      <Router>
        <Routes>
          <Route path="/login" element={<Log />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/enterverificationcode" element={<EnterVerificationCode />} />
          <Route path="/register" element={<Register />} />
          <Route path="/form" element={<Forms />} />
          <Route path="/profile" element={<Prof />} />
          <Route path="/edit" element={<Edit />} />
          <Route path="/createpassword" element={<CreatePassword />} />
          <Route path="/home" element={<Home />} />
        </Routes>
      </Router>
    );
  };
  return (
    <>{renderContent()}</>
  );
}

export default App;