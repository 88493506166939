import { useState, useEffect } from 'react';
import './Carousel.css';  // Archivo CSS para estilos
import carrusel1 from '../../img/carrusel_1.jpg';
import carrusel2 from '../../img/carrusel_2.jpg';
import carrusel3 from '../../img/carrusel_3.jpg';
import imagen_wot from '../../img/WoT.png';

const images = [
  carrusel1,
  carrusel2,
  carrusel3
];
const texts = [
  'Plataforma que conecta talento femenino de forma directa y visibiliza a las mujeres en empresas y organizaciones.',
  'Sin sesgos, perfiles ciegos y ajustes según experiencia para las búsquedas.',
  'Hoy más de 3000 mujeres son usuarias de Woman Talent.'
];


export default function Carousel() {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 7000); // Cambia de imagen cada 3 segundos
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="carousel">
      <div className="slide_carousel">
        <img
          src={images[currentIndex]}
          alt="Carousel"
          className="image_carousel"
        />
        <div className="overlay_carousel">
          <p className="overlay-text_carousel">
            {texts[currentIndex]} {/* Cambia el texto según la imagen */}
          </p>
          
        </div>
      </div>
    </div>
  );
}
