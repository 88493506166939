import React from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

export function NormalInput({ text, value, onInputChange }) {
    return (
        <Box className='box-user-login'>
          <FormControl variant="outlined" sx={{ width: {
            xs: '120%',
            sm: '300px',
            md: '400px', 
            lg: '400px',
            },transform: {
              xs: 'translateX(-5%)',  
              sm: 'none',              
            }, backgroundColor: '#F5F5F5' }}>
            <InputLabel htmlFor={value} sx={{ fontFamily: 'Montserrat', color: '#9D9D9D' }}>{text}</InputLabel>
            <OutlinedInput
              style={{ fontFamily: 'Montserrat' }}
              id={value}
              label={text}
              onChange={onInputChange}/>
          </FormControl>
        </Box>
      );
}