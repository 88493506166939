import React, { useEffect, useState } from 'react';
import './Profile.css';
import cityImage from '../../img/City.png';
import Resume from './Resume';
import { adapterForm } from '../../Adapter.js';
import { useNavigate } from 'react-router-dom';
import Edit from './Editar';
import axios from 'axios';
import LinearProgress from '@mui/material/LinearProgress';
import { Box } from '@mui/material';


const get_cv = async (token) => {
    try{
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/files/getcv`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        });
        return response;
    } catch (error) {
        return error;
    }
}

const Profile = () => {
    const token = localStorage.getItem('jwtToken');
    const [userData, setUserData] = useState({});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true); 
    const [activeInfo, setActiveInfo] = useState(1); 
    const [editingStep, setEditingStep] = useState(null);
    const [cv, setCv] = useState("")
    const [porcentajeProgress, setPorcentajeProgress] = useState(0);

    const navigate = useNavigate();

    const handleInfoChange = (infoNumber) => {
        setActiveInfo(infoNumber);
    };

    const handleEditClick = (step) => {
        setEditingStep(step);
    };

    const handleCancelEdit = () => { // Aquí está el cambio
        setEditingStep(null);
    };

    const porcentaje = (datos) => {
        const camposObligatorios = [
          'nombre',
          'apellido',
          'rut',
          'celular',
          'mail',
          'paisDomicilio',
          'intereses',
          'profesion',
          'universidad',
          'aniosExperiencia',
          'experienciaDirectorios',
          'altaDireccion',
          'areas',
          'competencia',
          'disponibilidad',
          'tipoJornada',
          'posibilidadCambiarseRegion',
          'tipoModalidad',
          'disposicion_viajar',
        ];
      
        let camposCompletados = 0;
      
        for (const campo of camposObligatorios) {
          if (campo in datos && datos[campo] !== null && datos[campo] !== undefined) {
            if (Array.isArray(datos[campo]) && datos[campo].length === 0) {
              continue; // Ignorar campos de arrays vacíos
            }
            camposCompletados++;
            //console.log(`Campo completado: ${campo}`);
          }
        }
      
        const progreso = (camposCompletados / camposObligatorios.length) * 100;
        setPorcentajeProgress(progreso);
      };  


    const sendDataToBackend = async (token, datos) => {
        try {
            if (datos.profesion[0] !== datos.profesion1) {
                //cambiar el datos.profesion[0] por datos.profesion1
                datos.profesion[0].id = datos.profesion1;
            }
            //datos profesion 2
            if (datos.profesion[1] !== datos.profesion2 || !datos.profesion[1]) {
                if (!datos.profesion[1]) {
                    datos.profesion[1] = {};  // Creamos un nuevo objeto si no existe
                }
                datos.profesion[1].id = datos.profesion2;
            }
            // Transforma los nombres de los campos según lo que espera el backend
            const dataToSend = adapterForm(datos); // el adapterForm hace que los datos esten en el formato del back
            // Realiza la solicitud PATCH
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/edit_profile`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(dataToSend),
            });
    
            if (!response.ok) {
                const errorMessage = await response.text();
                console.error(`Error en la solicitud PATCH: ${errorMessage}`);
            } else {
                // Verificar si la respuesta tiene contenido antes de parsear
                const responseText = await response.text();
                if (responseText) {
                    const jsonResponse = JSON.parse(responseText);
                    console.log('Datos enviados exitosamente');
                } else {
                    console.log('Datos enviados exitosamente, sin respuesta JSON');
                }
            }
        } catch (error) {
            console.error('Error en la solicitud PATCH:', error);
        }
    };

    const handleDelete = (step) => {
        setUserData((prevData) => {
            const newData = { ...prevData };
    
            switch (step) {
                case 2: // Eliminar Profesiones
                    newData.profesion = [];
                    break;
                case 3: // Eliminar Universidad
                    newData.universidad = null;
                    break;
                case 4: // Eliminar Postgrado
                    newData.postgrado = null;
                    break;
                case 5: // Eliminar Cargo Actual
                    newData.cargo = null;
                    newData.empresa_actual = "";
                    newData.industria = null;
                    break;
                case 6: // Eliminar Cargo Adicional
                    newData.aditionalCargo = null;
                    newData.empresa_adicional = "";
                    newData.aditionalIndustria = null;
                    break;
                case 12: // Eliminar Experiencia en Alta Dirección
                    newData.altaDireccion = null;
                    break;
                case 10: // Eliminar Experiencia en Directorios
                    newData.experienciaDirectorios = null;
                    break;
                case 7: // Eliminar Años de experiencia
                    newData.aniosExperiencia = null;
                    break;
                case 11: // Eliminar Áreas de experiencia
                    newData.areas = [];
                    break;
                case 8: // Eliminar Industrias con experiencia
                    newData.industrias = [];
                    break;
                case 9: // Eliminar Competencias
                    newData.competencia = [];
                    break;
                case 13: // Eliminar Disponibilidad para Trabajar
                    newData.disponibilidad = [];
                    break;
                case 15: // Eliminar Disponibilidad de Jornada
                    newData.tipoJornada = null;
                    break;
                case 16: // Eliminar Modalidad de Preferencia
                    newData.tipoModalidad = null;
                    break;
                case 17: // Eliminar Disposición para Viajar
                    newData.disposicion_viajar = null;
                    break;
                case 14: // Eliminar Posibilidad de cambiarse de región
                    newData.posibilidadCambiarseRegion = null;
                    break;
                case 19: // Eliminar Idiomas
                    newData.idiomas = [];
                    break;
                case 20: // Eliminar Factor de inclusión laboral
                    newData.factor = null;
                    break;
                case 22: // Eliminar Pueblo Originario
                    newData.nombrePuebloOriginario = null;
                    break;
                case 21: // Eliminar Relación con alguna región del país
                    newData.regionCompromiso = null;
                    break;
                case 24: // Eliminar Respuesta formulario personalidad
                    newData.personalidad = null;
                    break;
                case 23: // Eliminar Redes Sociales
                    newData.redesSociales = null;
                    break;
                case 25: // Eliminar Contactos de Verificación
                    newData.contactos_verificacion[0] = []
                    break;

                case 26: // Eliminar Contactos de Verificación
                    newData.contactos_verificacion[1] = []
                    break;
                // Añadir más casos según lo que necesites eliminar.
                case 27: 
                    newData.herramientas = null;
                    break;
                case 28: 
                    newData.subareas = []; 
                    newData.otraSubarea = null;
                    break;
                default:
                    break;
            }
    
            // Enviar los datos actualizados al backend
            sendDataToBackend(token, newData);
    
            // Actualizar el porcentaje basado en los datos nuevos
            porcentaje(newData);
    
            // Retornar los datos nuevos para actualizar el estado
            return newData;
        });
    };
    

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/users/get_profile`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                const data = await response.json();
                setUserData(data);
                
                setLoading(false);
                const responseCv = await get_cv(token);
                if(responseCv.status === 200){
                    setCv(responseCv.data.cvlink)
                }
                porcentaje(data);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };
        fetchUserData();
        
    }, [token, editingStep]);

    if (loading) {
        return <div>Cargando...</div>;
    }


    return (
        <div className="profile-container">
            {editingStep ? (
                <Edit currentStep={editingStep} onCancel={handleCancelEdit} />
            ) : (
                <>
                    <div className="profile-header">
                        <div className="profile-cover">
                            <img src={cityImage} alt="City Background" className="cover-image" />
                            </div>
                            <div className="profile-info">
                                <button className="btn-edit-profile" onClick={() => navigate('/edit')}>Cambiar contraseña</button>
                                {userData && userData.nombre && (
                                    <div className="profile-details">
                                        <h2>{userData.nombre} {userData.apellido}</h2>
                                        <p className="profile-career">
                                            {userData.profesion[0]?.nombre} | {userData.profesion[1]?.nombre}
                                        </p>
                                        <p className="profile-description">
                                            {userData.brief}
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="linea_gris"></div>
                        
                    </div>

                    <div className="profile-content">
                        <div className="profile">
                            <div className="profile-form">
                                <h3>Formulario</h3>
                                <p>En este formulario te preguntamos lo necesario para crear tu perfil mujer en WOT</p>
                                <p className="pink-text">Tiempo de llenado: 7 minutos aprox</p>
                                <LinearProgress 
                                    sx={(theme) => ({
                                        height: 7, // Ajusta la altura de la barra de progreso
                                        '& .MuiLinearProgress-bar': {
                                            backgroundColor: '#E6007E', // Color de la barra de progreso (la parte que se llena)
                                        },
                                        '& .MuiLinearProgress-dashed': {
                                            background: 'none', // Asegura que no haya un efecto dashed (punteado) en el track
                                        },
                                        borderRadius: 5, // Redondea las esquinas
                                        width: '50vw', // Ancho en pantallas normales
                                        marginLeft: '1vw',
                                        marginTop: '2vh',

                                        // Media query para pantallas móviles
                                        [theme.breakpoints.down('sm')]: {
                                            width: '85vw', // Ancho en pantallas pequeñas/móviles
                                            marginLeft: '5vw', // Ajuste de margen para centrar
                                            height: '0.5vh',
                                        },
                                    })}
                                    variant="determinate"
                                    value={porcentajeProgress}

                                />
            
                                
                                <Box
                                    component="p"
                                    sx={{
                                        marginLeft: { xs: '45vw !important', sm: '20vw !important', md: '20vw !important', lg: '20vw !important' },
                                        marginTop: { xs: '2vh', sm: '1vh'  },
                                        fontSize: { xs: '12px', sm: '16px' }, // Cambia según el tamaño de pantalla
                                    }}
                                >
                                    {Math.round(porcentajeProgress)}%
                                </Box>
                                {/* <div className="linea_gris"></div> */}
                                <button className="btn-completar-formulario" onClick={() => navigate('/form')}>
                                    Completar formulario
                                </button>
                            </div>

                            <div className="rectangulo_seccion">
                                <p className="perfil-letra">Información del Perfil</p>
                                <div className="horizontal-bar">
                                    <button
                                        onClick={() => handleInfoChange(1)}
                                        className={activeInfo === 1 ? "button-activate-infoprofesional" : "button-inactivate-infoprofesional"}
                                    >
                                        Información profesional
                                    </button>
                                    <button
                                        onClick={() => handleInfoChange(2)}
                                        className={activeInfo === 2 ? "button-activate" : "button-inactivate"}
                                    >
                                        Disponibilidad
                                    </button>
                                    <button
                                        onClick={() => handleInfoChange(3)}
                                        className={activeInfo === 3 ? "button-activate-infoadicional" : "button-inactivate-infoadicional"}
                                    >
                                        Información adicional
                                    </button>
                                    <button
                                        onClick={() => handleInfoChange(4)}
                                        className={activeInfo === 4 ? "button-activate" : "button-inactivate"}
                                    >
                                        Referencias
                                    </button>
                                </div>
                                <div className="select-bar">
                                    <select value={activeInfo} onChange={(e) => handleInfoChange(parseInt(e.target.value))}>
                                        <option value={1}>Información profesional</option>
                                        <option value={2}>Disponibilidad</option>
                                        <option value={3}>Información adicional</option>
                                        <option value={4}>Referencias</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="personal-info-card">
                            <h3>Información Personal</h3>
                            <p><strong>RUT:</strong> {userData.rut}</p>
                            <p><strong>Celular:</strong> {userData.celular}</p>
                            <p><strong>Mail:</strong> {userData.mail}</p>
                            <p><strong>País de domicilio:</strong> {userData.paisDomicilio?.pais}</p>
                            {userData.paisDomicilio?.id === 1 && (
                                <p><strong>Región de domicilio:</strong> {userData.regionActualDomicilio?.nombre}</p>
                            )}
                            <p><strong>3 Intereses personales:</strong> {userData.intereses}</p>
                            { cv !== "" && (
                            <p className="texto-centrado">
                                {cv !== "" && <a href={cv} target="_blank" rel="noopener noreferrer" className="dato-cv">Curriculum de {userData.nombre}</a>}
                            </p>
                            )}
                            <p className="personal-info-card-help">Si necesitas ayuda escribe a help@womantalent.cl</p>

                            {/* <button className="btn-edit-profile">Editar perfil</button> */}
                        </div>

                        <div className="resumen">
                            {activeInfo === 1 && (
                                <div className="sections-container">
                                    <Resume
                                        title="Profesiones"
                                        description={
                                            userData.profesion && userData.profesion.length > 0
                                                ? userData.profesion.map(item => item.nombre).join(', ')
                                                : 'No hay datos sobre la profesión'
                                        }
                                        step={2}
                                        onEdit={handleEditClick}
                                        onDelete={handleDelete} 
                                    />
                                    <Resume
                                        title="Universidad"
                                        description={userData.universidad ? userData.universidad : 'No hay datos sobre la universidad'}
                                        step={3}
                                        onEdit={handleEditClick}
                                        onDelete={handleDelete} 
                                    />
                                    <Resume
                                        title="Postgrado"
                                        description={userData.postgrado ? userData.postgrado : 'No hay datos sobre el postgrado'}
                                        step={4}
                                        onEdit={handleEditClick}
                                        onDelete={handleDelete} 
                                    />
                                    <Resume
                                        title="Cargo Actual"
                                        description={`${userData.cargo?.cargo || 'No hay datos sobre el cargo'}${userData.empresa_actual ? `, ${userData.empresa_actual}` : ''}${userData.industria?.nombre_industria ? `\nIndustria: ${userData.industria.nombre_industria}` : ''}`}
                                        step={5}
                                        onEdit={handleEditClick}
                                        onDelete={handleDelete} 
                                    />
                                    <Resume
                                        title="Cargo Adicional"
                                        description={`${userData.aditionalCargo?.cargo || 'No'}${userData.empresa_adicional ? `, ${userData.empresa_adicional}` : ''}${userData.aditionalIndustria?.nombre_industria ? `\nIndustria: ${userData.aditionalIndustria.nombre_industria}` : ''}`}
                                        step={6}
                                        onEdit={handleEditClick}
                                        onDelete={handleDelete} 
                                    />
                                    <Resume
                                        title="Experiencia en Alta Dirección"
                                        description={userData.altaDireccion ? 'Sí' : 'No'}
                                        step={12}
                                        onEdit={handleEditClick}
                                        onDelete={handleDelete} 
                                    />
                                    <Resume
                                        title="Experiencia en Directorios"
                                        description={userData.experienciaDirectorios ? 'Sí' : 'No'}
                                        step={10}
                                        onEdit={handleEditClick}
                                        onDelete={handleDelete} 
                                    />
                                    <Resume
                                        title="Años de experiencia"
                                        description={userData.aniosExperiencia ? `${userData.aniosExperiencia.rango_experiencia_desde} - ${userData.aniosExperiencia.rango_experiencia_hasta} años` : 'No hay datos sobre los años de experiencia'}
                                        step={7}
                                        onEdit={handleEditClick}
                                        onDelete={handleDelete} 
                                    />
                                    <Resume
                                        title="Áreas de experiencia"
                                        description={
                                            userData.areas && userData.areas.length > 0
                                                ? userData.areas.map(item => item.nombre).join(', ')
                                                : 'No hay datos sobre las áreas de experiencia'
                                        }
                                        step={11}
                                        onEdit={handleEditClick}
                                        onDelete={handleDelete} 
                                    />
                                    <Resume
                                        title="Subáreas de experiencia"
                                        description={
                                            (userData.subareas && userData.subareas.length > 0) || userData.otrasSubareas
                                                ? (userData.subareas && userData.subareas.length > 0
                                                    ? userData.subareas.map(item => item.nombre).join(', ')
                                                    : '') + (userData.otrasSubareas 
                                                        ? (userData.subareas && userData.subareas.length > 0 ? ', ' : '') + userData.otrasSubareas
                                                        : '')
                                                : 'No hay datos sobre las áreas de experiencia'
                                        }
                                        step={28}
                                        onEdit={handleEditClick}
                                        onDelete={handleDelete} 
                                    />
                                    <Resume
                                        title="Industrias con experiencia"
                                        description={userData.industrias && userData.industrias.length > 0
                                            ? userData.industrias.map(item => item.nombre_industria).join(', ')
                                            : 'No hay datos sobre las industrias de experiencia'}
                                        step={8}
                                        onEdit={handleEditClick}
                                        onDelete={handleDelete} 
                                    />
                                    <Resume
                                        title="Competencias"
                                        description={userData.competencia && userData.competencia.length > 0
                                            ? userData.competencia.map(item => item.competencia).join(', ')
                                            : 'No hay datos sobre las competencias'}
                                        step={9}
                                        onEdit={handleEditClick}
                                        onDelete={handleDelete} 
                                    />
                                </div>
                            )}

                            {activeInfo === 2 && (
                                <div className="sections-container">
                                    <Resume
                                        title="Disponibilidad para Trabajar"
                                        description={userData.disponibilidad?.map(item => item.disponibilidad).join(', ') || 'No hay datos sobre la disponibilidad'}
                                        step={13}
                                        onEdit={handleEditClick}
                                        onDelete={handleDelete} 
                                    />
                                    <Resume
                                        title="Disponibilidad de Jornada"
                                        description={userData.tipoJornada?.tipoJornada}
                                        step={15}
                                        onEdit={handleEditClick}
                                        onDelete={handleDelete} 
                                    />
                                    <Resume
                                        title="Modalidad de Preferencia"
                                        description={userData.tipoModalidad?.tipoModalidad}
                                        step={16}
                                        onEdit={handleEditClick}
                                        onDelete={handleDelete} 
                                    />
                                    <Resume
                                        title="Disposición para Viajar"
                                        description={userData.disposicion_viajar ? 'Sí' : 'No'}
                                        step={17}
                                        onEdit={handleEditClick}
                                        onDelete={handleDelete} 
                                    />
                                    <Resume
                                        title="Posibilidad de cambiarse de región"
                                        description={userData.posibilidadCambiarseRegion ? userData.posibilidadCambiarseRegion.posibilidad : 'No hay datos sobre la posibilidad de cambiarse de región'}
                                        step={14}
                                        onEdit={handleEditClick}
                                        onDelete={handleDelete} 
                                    />
                                </div>
                            )}
                            {activeInfo === 3 && (
                                <div className="sections-container">
                                    <Resume
                                        title="Dominio de idioma" 
                                        description={userData.idiomas && userData.idiomas.length > 0
                                            ? userData.idiomas
                                                .filter((item) => item && item.nombre) 
                                                .map((item) => item.nombre)
                                                .join(', ') 
                                            : 'No hay datos sobre el dominio de otros idiomas'
                                        }
                                        step={19}
                                        onEdit={handleEditClick}
                                        onDelete={handleDelete} 
                                    />
                                    <Resume
                                        title="Herramientas Adicionales" 
                                        description={userData.herramientas ? userData.herramientas : 'No hay información sobre herramientas adicionales'}
                                        step={27}
                                        onEdit={handleEditClick}
                                        onDelete={handleDelete} 
                                    />
                                    <Resume
                                        title="Factor de inclusión laboral" 
                                        description={userData.factor ? userData.factor : 'No'}
                                        step={20}
                                        onEdit={handleEditClick}
                                        onDelete={handleDelete} 
                                    />
                                    <Resume
                                        title="Pueblo originario" 
                                        description={userData.nombrePuebloOriginario ? userData.nombrePuebloOriginario : 'No'} 
                                        step={22}
                                        onEdit={handleEditClick}
                                        onDelete={handleDelete} 
                                    />
                                    <Resume
                                        title="Relación con alguna región del país" 
                                        description={userData.regionCompromiso ? userData.regionCompromiso.nombre : 'No'}
                                        step={21}
                                        onEdit={handleEditClick}
                                        onDelete={handleDelete} 
                                    />
                                    <Resume
                                        title="Respuesta formulario personalidad" 
                                        description={userData.personalidad ? userData.personalidad.personalidad : 'No hay datos sobre el formulario de personalidad'}
                                        step={24}
                                        onEdit={handleEditClick}
                                        onDelete={handleDelete} 
                                    />
                                    <Resume
                                        title="Redes sociales" 
                                        description={userData.redesSociales}
                                        step={23}
                                        onEdit={handleEditClick}
                                        onDelete={handleDelete} 
                                    />
                                </div>
                            )}
                            {activeInfo === 4 && (
                                <div className="sections-container">
                                    <Resume
                                        title="Contacto de Verificación"
                                        description={
                                            userData && userData.contactos_verificacion && userData.contactos_verificacion[0] 
                                                ? `Nombre: ${userData.contactos_verificacion[0].nombre || ''}\nTeléfono: ${userData.contactos_verificacion[0].telefono || ''}\nEmail: ${userData.contactos_verificacion[0].email || ''}` 
                                                : 'No hay información de contacto disponible'
                                        }                                        
                                        step={25}
                                        onEdit={handleEditClick}
                                        onDelete={handleDelete} 
                                    />

                                    <Resume
                                        title="Segundo Contacto de Verificación"
                                        description={
                                            userData && userData.contactos_verificacion && userData.contactos_verificacion[1] 
                                                ? `Nombre: ${userData.contactos_verificacion[1].nombre || ''}\nTeléfono: ${userData.contactos_verificacion[1].telefono || ''}\nEmail: ${userData.contactos_verificacion[1].email || ''}` 
                                                : 'No hay información de contacto disponible'
                                        }
                                        
                                        step={26}
                                        onEdit={handleEditClick}
                                        onDelete={handleDelete} 
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Profile;




                