import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

export function NextButton({ handleNext, buttonText, buttonColor, marginTop, marginLeft,  marginLeftMobile,  marginTopMobile }) {
  
  // Definimos el tamaño del botón y otros estilos
  const buttonStyles = {
    fontFamily: 'Montserrat',
    width: '150px', // Ancho por defecto
    height: '50px', // Altura por defecto
    textTransform: 'none',
    fontSize: '15px', // Tamaño de fuente por defecto
    border: `2px solid ${buttonColor}`,
    color: buttonColor,
    backgroundColor: 'white',
    boxShadow: 'none',
    //marginTop: marginTop || '75.5rem', // Espaciado superior
    //marginLeft: marginLeft || '63rem', // Margen izquierdo
    position: 'absolute',
    zIndex: '7',
    transition: 'background-color 0.3s ease, color 0.3s ease', // Transición suave
  };

  // Estilos para el hover
  const hoverStyles = {
    backgroundColor: '#EE4296', // Color rosado en hover
    color: 'white', // Cambia el color del texto a blanco en hover
  };

  // Detectar si es un dispositivo móvil
  const isMobile = window.innerWidth <= 600;

  // Modificar estilos para dispositivos móviles
  if (isMobile) {
    buttonStyles.width = '70%'; // Ancho del botón en móvil
    buttonStyles.height = '40px'; // Altura del botón en móvil
    buttonStyles.fontSize = '15px'; // Tamaño de fuente en móvil
    buttonStyles.marginLeft = marginLeftMobile || '-3%'; // Ajustar margen izquierdo en móvil
    buttonStyles.marginTop=  marginTopMobile ||'-17rem'
  }

  return (
    <Box className='box-user-login'>
      <Button 
        onClick={handleNext} 
        variant="contained" 
        style={buttonStyles}
        onMouseOver={(e) => {
          e.currentTarget.style.backgroundColor = hoverStyles.backgroundColor;
          e.currentTarget.style.color = hoverStyles.color;
        }}
        onMouseOut={(e) => {
          e.currentTarget.style.backgroundColor = 'white';
          e.currentTarget.style.color = buttonColor;
        }}
      >
        {buttonText}
      </Button>
    </Box>
  );
}
