import React, { useState } from 'react';
import './Auth.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { Images } from './Images';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { SubmitButton } from './SubmitButton';
import { ThemeProvider } from "@mui/material";
import { MUItheme } from './MUItheme';
import { HiddenInput } from './HiddenInput';
import axios from 'axios';

async function fetchPaises() {
  try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/obtener_listas/paises`);
      return response.data;
  } catch (error) {
      console.error('Error al obtener los países:', error);
      throw error;
  }
}

async function fetchRegiones() {
  try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/obtener_listas/regiones`);
      return response.data;
  } catch (error) {
      console.error('Error al obtener las regiones:', error);
      throw error;
  }
}

async function fetchProfesiones() {
  try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/obtener_listas/profesiones`);
      return response.data;
  } catch (error) {
      console.error('Error al obtener las profesiones:', error);
      throw error;
  }
}

async function fetchUniversidades() {
  try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/obtener_listas/universidades`);
      return response.data;
  } catch (error) {
      console.error('Error al obtener las universidades:', error);
      throw error;
  }
}

async function fetchCargos() {
  try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/obtener_listas/cargos`);
      return response.data;
  } catch (error) {
      console.error('Error al obtener los cargos:', error);
      throw error;
  }
}

async function fetchIndustrias() {
  try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/obtener_listas/industrias`);
      return response.data;
  } catch (error) {
      console.error('Error al obtener las industrias:', error);
      throw error;
  }
}

async function fetchAniosExperiencia() {
  try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/obtener_listas/rango_anos`);
      const aniosOrdenados = response.data.sort((a, b) => {
          // Extraer los números iniciales del rango para comparar
          const numA = parseInt(a.rango.split('-')[0], 10);
          const numB = parseInt(b.rango.split('-')[0], 10);
          return numA - numB; // Ordenar en forma ascendente
      });
      return aniosOrdenados;
  } catch (error) {
      console.error('Error al obtener los años de experiencia:', error);
      throw error;
  }
}

async function fetchCompetencias() {
  try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/obtener_listas/competencias`);
      return response.data;
  } catch (error) {
      console.error('Error al obtener las competencias:', error);
      throw error;
  }
}

async function fetchAreasExperiencia() {
  try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/obtener_listas/areas`);
      return response.data;
  } catch (error) {
      console.error('Error al obtener las areas:', error);
      throw error;
  }
}

async function fetchDisponibilidades() {
  try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/obtener_listas/disponibilidades`);
      return response.data;
  } catch (error) {
      console.error('Error al obtener las disponibilidades:', error);
      throw error;
  }
}

async function fetchJornadas() {
  try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/obtener_listas/jornadas`);
      return response.data;
  } catch (error) {
      console.error('Error al obtener las jornadas:', error);
      throw error;
  }
}

async function fetchModalidades() {
  try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/obtener_listas/modalidades`);
      return response.data;
  } catch (error) {
      console.error('Error al obtener las modalidades:', error);
      throw error;
  }
}

async function fetchIdiomas() {
  try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/obtener_listas/idiomas`);
      return response.data;
  } catch (error) {
      console.error('Error al obtener las idiomas:', error);
      throw error;
  }
}

async function fetchConocio() {
  try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/obtener_listas/conocio_wot`);
      return response.data;
  } catch (error) {
      console.error('Error al obtener como conocio WoT:', error);
      throw error;
  }
}

async function fetchPersonalidad() {
  try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/obtener_listas/formularios`);
      return response.data;
  } catch (error) {
      console.error('Error al obtener formulario de personalidad:', error);
      throw error;
  }
}

export function CreatePassword() {

  const location = useLocation();
  const nombre = location.state.nombre;
  const apellido = location.state.apellido;
  const mail = location.state.mail;
  const [responseData, setResponseData] = useState({});
  const [responseStatus, setResponseStatus] = useState();
  const [recaptchaToken, setRecaptchaToken] = useState('');

  const [formData, setFormData] = useState({
    password: '',
    passwordConfirmation: '',
  });

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    setError(false);
    setErrorMatch(false);
    setErrorFormat(false);
    setLoading(true);
    
    try {
        if (formData.password != formData.passwordConfirmation){
          setErrorMatch(true);
          toast.error('Las contraseñas no coinciden', {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
        }
        
        else{
          toast.success('Creando tu cuenta...', {
            position: "top-center",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/signup`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        name: nombre,
                        lastName: apellido,
                        mail,
                        password: formData.password,
                        recaptchaToken,
                    }),
                });
                const responseData = await response.json();
                if (response.status == 201) {
                    localStorage.setItem('jwtToken', responseData.access_token);
                    localStorage.setItem('userId', responseData.id);
                    setLoading(false);
                    navigate('/profile');  
                    const [
                        paises, regiones, profesiones, universidades, cargos, industrias,
                        anios, competencias, areas, disponibilidades, jornadas, modalidades,
                        idiomas, conocio, personalidades
                    ] = await Promise.all([
                        fetchPaises(),
                        fetchRegiones(),
                        fetchProfesiones(),
                        fetchUniversidades(),
                        fetchCargos(),
                        fetchIndustrias(),
                        fetchAniosExperiencia(),
                        fetchCompetencias(),
                        fetchAreasExperiencia(),
                        fetchDisponibilidades(),
                        fetchJornadas(),
                        fetchModalidades(),
                        fetchIdiomas(),
                        fetchConocio(),
                        fetchPersonalidad(),
                    ]);
    
                    // Almacena los datos en localStorage
                    localStorage.setItem('datosPaises', JSON.stringify(paises));
                    localStorage.setItem('datosRegion', JSON.stringify(regiones));
                    localStorage.setItem('datosProfesiones', JSON.stringify(profesiones));
                    localStorage.setItem('datosUniversidades', JSON.stringify(universidades));
                    localStorage.setItem('datosCargos', JSON.stringify(cargos));
                    localStorage.setItem('datosIndustrias', JSON.stringify(industrias));
                    localStorage.setItem('aniosExperiencia', JSON.stringify(anios));
                    localStorage.setItem('competencias', JSON.stringify(competencias));
                    localStorage.setItem('areasExperiencia', JSON.stringify(areas));
                    localStorage.setItem('disponibilidades', JSON.stringify(disponibilidades));
                    localStorage.setItem('jornadas', JSON.stringify(jornadas));
                    localStorage.setItem('modalidades', JSON.stringify(modalidades));
                    localStorage.setItem('idiomas', JSON.stringify(idiomas));
                    localStorage.setItem('conocio', JSON.stringify(conocio));
                    localStorage.setItem('personalidades', JSON.stringify(personalidades)); 
                  } else{
                    console.log("ERROR", responseData.error);
                    setError(true);
                    toast.error(responseData.error||'Hubo un error al crear tu cuenta', {
                      position: "top-center",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                      });
                  }
        
            } catch (error) {
                // Handle errors here
                console.log(error.message);
                console.error(error);
                
            }
        }
    } catch (error) {
        setErrorFormat(true);
        toast.error('Hubo un error al crear tu cuenta', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    } finally {
        setLoading(false);
    }
  
  };

  const handleRecaptcha = (value) => {
    setRecaptchaToken(value);
  }

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMatch, setErrorMatch] = useState(false);
  const [errorFormat, setErrorFormat] = useState(false);
  return (
    <GoogleReCaptchaProvider reCaptchaKey= {process.env.REACT_APP_CLAVE_SITIO}>
      <ToastContainer />
      <div className='main-container'>
        <Images />
        <div className='main-container-login'>
            <form onSubmit={handleLogin} className="form-container">
            <div className='clave-container'>
            <ThemeProvider theme={MUItheme}>
                <h3 className='message-login'>Crea tú clave</h3>
                <h3 className='enter-text'>Utiliza al menos  8 caracteres, 1 mayúscula, 1 minúscula, 1 número y un símbolo.</h3>
                <link href='https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap' rel='stylesheet'></link>
                <HiddenInput text='Contraseña' type={'password'} onInputChange={handleInputChange}/>
                <HiddenInput text='Repite la contraseña' type={'passwordConfirmation'} onInputChange={handleInputChange}/>
            </ThemeProvider>
            </div>
            <div className="recaptcha-container">
                <GoogleReCaptcha onVerify={handleRecaptcha} action="submit"  />
            </div>
            <SubmitButton handleLogin={handleLogin} buttonColor="#E6007E" buttonText="Entrar"/>
            </form>
        </div>
      </div>
    </GoogleReCaptchaProvider>
  );
  
}

export default CreatePassword;