import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

export function SubmitButton({ handleLogin, buttonText, buttonColor }) {
  return (
    <Box className='box-user-login'>
      <Button
        onClick={handleLogin}
        variant="contained"
        sx={{
          fontFamily: 'Montserrat',
          width: '400px',
          height: '62px',
          textTransform: 'none',
          fontSize: '15px',
          backgroundColor: buttonColor,
          '@media (max-width: 768px)': {
            width: '100%',  // Full width on mobile
            height: '50px', // Slightly smaller height on mobile
            fontSize: '14px', // Adjust font size for smaller screens
          },
        }}
      >
        {buttonText}
      </Button>
    </Box>
  );
}

