import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Typography } from '@mui/material';

export const PasswordCriteria = ({ isValid, text }) => {
  return (
    <Box display="flex" alignItems="center"  sx={{ 
      mr: {
        xs: 8,    // Sin margen derecho en pantallas pequeñas (o ajusta según tus necesidades)
        sm: 20    // Margen derecho de 20 en pantallas más grandes
      },
      mb: {
        xs: 1, 
        sm: 2
      }, 
      mt: {
        xs: 1, 
        sm: 4
      }
    }}>
      {isValid ? (
        <CheckCircleIcon sx={{ color: '#e6007e' }} />
      ) : (
        <CancelIcon sx={{ color: '#ccc' }} />
      )}
      <Typography sx={{ color: isValid ? '#e6007e' : '#9D9D9D', marginLeft: {
        xs: '8px', 
        sm: '0vw',
        }, 
        width: {
          xs: '50vw', // Para pantallas pequeñas (mobile)
          sm: '15vw'
        } , 
        textAlign: 'left' }}>
        {text}
      </Typography>
    </Box>
  );
};
