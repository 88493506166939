import React from 'react';
import './Statistics.css';
import { useNavigate } from 'react-router-dom';

export default function Statistics() {
  const navigate = useNavigate();
  return (
    <div className="statistics">
      <div className="stat-item">
        <h3 className="title">1. Mantén siempre tu información actualizada</h3>
        <p className="description">
          Mantén tu perfil completo y actualizado para que el algoritmo de búsqueda 
          pueda encontrar las mejores coincidencias con las oportunidades laborales ideales para ti.
        </p>
      </div>
      <div className="stat-item">
        <h3 className="title">2. Usamos un algoritmo de Match personalizado</h3>
        <p className="description">Conectamos a las mujeres que cumplen con el perfil solicitado en las empresas mediante un algoritmo que hace match con las mejores candidatas según la información que nos dan.</p>
      </div>
      <div className="stat-item">
        <h3 className="title">3. Somos más de 3000 usuarias, comparte nuestra red con más mujeres</h3>
        <p className="description">Formamos una comunidad creciente de mujeres talentosas en busca de nuevas oportunidades, ayúdanos a compartirla con más mujeres!</p>
      </div>
      <button 
        className="btn-completar-formulario-home" 
        onClick={() => navigate('/form')}
      >
        IR A COMPLETAR INFORMACIÓN
      </button>
      
      
    </div>
  );
}
